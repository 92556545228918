<template>
  <v-container grid-list-md>
    <v-row>
      <v-col :cols="12" md="6">
        <v-card v-if="mailbox">
          <v-card-title>Your Mailbox is Box {{ mailbox }}</v-card-title>
        </v-card>
        <v-card>
          <v-card-title>Package Preferences</v-card-title>
          <template v-if="phone == null || phone === ''">
            <v-card-text>
              <p>You are currently not set up to receive text notifications when you have a package ready to be picked up. If you would like to receive text notifications, please enter your 10-digit phone number below. This is currently only available for US numbers.</p>
              <v-form ref="form" v-model="valid">
                <v-text-field v-model="newPhone" :rules="rules" label="Phone Number" outlined style="max-width:250px"></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="!valid" text color="success" @click="addNumber">Add Phone Number</v-btn>
            </v-card-actions>
          </template>
          <template v-else>
            <v-card-text>
              <p>You are currently signed up to receive text notifications on this number: {{ phone }}. If you no longer wish to receive notifications to this number or if you need to change this number, please click the button below to remove it.</p>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="error" @click="removeNumber">Remove Phone Number</v-btn>
            </v-card-actions>
          </template>
          <v-card-text>
            <p>You can set up one or more other people to pick up packages on your behalf (like your roommate, coworker, work-study student, etc). To add someone as an authorized pickup person for your packages, click the Authorize Pickup Person button below and search for them by name, then select their name to add them to the list. To remove someone, click the red X next to their name.</p>
          </v-card-text>
          <v-list>
            <v-list-item v-for="({ name, user }, index) of proxies" :key="'user-' + user">
              <v-list-item-title>{{ name }}</v-list-item-title>
              <v-list-item-action>
                <v-btn icon @click="removeProxy(index)">
                  <v-icon color="error">fal fa-times-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-dialog v-model="addProxyDialog" width="400">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text color="success">Authorize Pickup Person</v-btn>
              </template>
              <v-card>
                <v-card-title>Add Authorized Pickup Person</v-card-title>
                <v-card-text>
                  <directory-search :includePhoto="true" :filter="proxySearchFilter" value-field="pidm" searchLabel="Name Search" @select="(person) => selectUser(person)"></directory-search>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="addProxyDialog = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
        <v-card style="margin-top:1em;display:none">
          <v-card-title>Forwarding Address</v-card-title>
          <v-card-text>At the end of each semester you will be asked to update your forwarding address. This will help us to forward mail to you that is inadvertenly sent here. Note that packages cannot be forwarded without a fee.</v-card-text>
          <v-card-text>
            <v-card>
              <v-card-title>Current forwarding address</v-card-title>
              <v-card-text>
                14049 Scenic Hwy<br/>
                Lookout Mountain, GA 30750
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" text>Confirm</v-btn>
                <v-btn text>Edit</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" text>Remove</v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" md="6">
        <v-card>
          <v-card-title>Packages</v-card-title>
          <v-card-text v-if="packages.length === 0">You do not have any packages ready to be picked up.</v-card-text>
          <template v-else>
            <v-card-text>You have {{ packages.length }} package{{ packages.length > 1 ? 's' : '' }} ready to be picked up. Information about {{ packages.length === 1 ? 'this package' : 'these packages'}} is below.</v-card-text>
            <v-list>
              <v-list-item v-for="({ barcode, packageType, createdAt }, index) in packages" :key="'package-' + index">
                <v-list-item-content>
                  <v-list-item-title>{{ packageType }}</v-list-item-title>
                  <v-list-item-subtitle>Received: {{ createdAt }}</v-list-item-subtitle>
                  <v-list-item-subtitle>Barcode: {{ barcode }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ref, onMounted, computed, watch } from '@vue/composition-api'

export default {
  components: {
    DirectorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const phone = ref('')
    const newPhone = ref('')
    const rules = ref([
      v => !!v || 'Required',
      v => v.replace(/[^0-9]/g, '').length === 10 || 'Must include exactly 10 numeric digits'
    ])
    const valid = ref(false)
    const mailbox = ref(null)

    const packages = ref([])

    const prefServ = root.$feathers.service('mailroom/preferences')

    function formatPhone (num) {
      if (num == null || num === '') return ''
      // Strip all non-numeric characters
      let number = num.replace(/[^0-9]/g, '')
      // Start with an open parenthesis and the first 3 digits
      let temp = '(' + number.substr(0, 3)
      // If they have entered at least 3 digits, add a closed parenthesis after the third digit with a space after
      // then the next 3 digits
      if (number.length >= 3) temp += ') ' + number.substr(3, 3)
      // If they have entered at least 6 digits (meaning the previous 2 blocks are complete) then add a dash and the next 4 digits
      // This will automatically strip off anything beyond a 10-digit number
      if (number.length >= 6) temp += '-' + number.substr(6, 4)
      return temp
    }

    watch(newPhone, () => {
      let temp = formatPhone(newPhone.value)
      if (temp !== newPhone.value) newPhone.value = temp
    })

    const prefId = ref('')

    onMounted(() => {
      // Check to see if the current user has set up their phone number in the system for text notifications
      prefServ.find({ query: { 'user.pidm': user.value.pidm, active: true } }).then(({ data }) => {
        if (data.length > 0) {
          prefId.value = data[0]._id
          phone.value = formatPhone(data[0].phone)
          proxies.value = data[0].proxy || []
        } else {
          prefServ.create({
            user: {
              pidm: user.value.pidm,
              directory: user.value.directoryId,
              name: user.value.name
            }
          }).then((data) => {
            prefId.value = data._id
          })
        }
      })
      // Check to see if the current user has any packages marked as ready to pick up
      root.$feathers.service('mailroom/package').find({ query: { 'user.pidm': user.value.pidm, status: 'Ready for Pickup' } }).then(({ data }) => {
        packages.value = data
      })
      root.$feathers.service('mailroom/mailbox').find({ query: { 'users.pidm': user.value.pidm } }).then(({ data }) => {
        if (data.length === 1) {
          mailbox.value = data[0].box
        }
      })
    })

    function addNumber () {
      if (prefId.value === '') {
        prefServ.create({
          user: {
            pidm: user.value.pidm,
            directory: user.value.directoryId,
            name: user.value.name
          },
          phone: newPhone.value
        }).then((data) => {
          phone.value = formatPhone(data.phone)
        })
      } else {
        prefServ.patch(prefId.value, { phone: newPhone.value }).then((data) => {
          phone.value = formatPhone(data.phone)
        })
      }
    }
    async function removeNumber () {
      try {
        const data = prefServ.update(prefId.value, { $unset: { phone: '' } })
        phone.value = data.phone
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Phone number removed successfully' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'Error removing phone number: ' + e })
      }
    }

    const proxies = ref([])
    const addProxyDialog = ref(false)
    async function selectUser ({ value: pidm, text: name }) {
      if (pidm != null && pidm !== '') {
        console.log({ pidm, name })
        addProxyDialog.value = false
        const { proxy } = await prefServ.patch(prefId.value, { $push: { proxy: { pidm, name } } })
        proxies.value = proxy
      }
    }
    const proxySearchFilter = computed(() => {
      const arr = [user.value.pidm]
      proxies.value.forEach(({ pidm }) => arr.push(pidm))
      return {
        pidm: { $nin: arr }
      }
    })
    async function removeProxy (index) {
      proxies.value.splice(index, 1)
      const { proxy } = await prefServ.patch(prefId.value, { proxy: proxies.value })
      proxies.value = proxy
    }

    return {
      phone,
      newPhone,
      rules,
      valid,
      mailbox,
      packages,
      addNumber,
      removeNumber,
      proxies,
      addProxyDialog,
      selectUser,
      proxySearchFilter,
      removeProxy
    }
  }
}
</script>
